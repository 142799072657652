* {
  box-sizing: border-box;
}
body {
  font-family: 'Roboto Flex', sans-serif;
  -webkit-font-smoothing: antialiased;
  background: #ffffff;
}
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}